import React, { useEffect } from "react";
import Helmet from "react-helmet"
import { graphql, useStaticQuery } from "gatsby";
import { getProperty } from "../../utils/helperFn";
import { getValueByKey } from "../../utils/helperFn";
import FeaturedImage from './../../assets/image/png/netomnia-featured-image.png';
import { Location } from '@reach/router';

// featuredImageUrl 
const Seo = ({ page, featuredImageUrl }) => {
    // Todo add variable page.
    const data = useStaticQuery(graphql`
        query allStrapiSeosQuery {
            allStrapiSeos(sort: {fields: strapiId, order: ASC}) {
                nodes {
                    meta_title
                    meta_description
                    featured_image {
                        childImageSharp {
                            fluid(maxWidth: 1000) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    page    
                }
            }
            allStrapiNewSeo(sort: {fields: strapiId, order: ASC}) {
                nodes {
                    meta_title
                    meta_description
                    featured_image {
                        childImageSharp {
                            fluid(maxWidth: 1000) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    page    
                }
            }
            allStrapiSeoLatest(sort: {fields: strapiId, order: ASC}) {
                nodes {
                    meta_title
                    meta_description
                    featured_image {
                        childImageSharp {
                            fluid(maxWidth: 1000) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    page    
                }
            }
            
        }      
    `);

    const seoData = getProperty(data, 'allStrapiSeos');
    const seo1Data = getProperty(data, 'allStrapiNewSeo');
    const seo2Data = getProperty(data, 'allStrapiSeoLatest');

    const totalData = seoData.nodes.concat(seo1Data.nodes, seo2Data.nodes)

    const sd = getValueByKey(totalData, 'page', page);

    useEffect(() => {
        addAltAttrs();

        if (typeof window !== 'undefined' && window) {
            window.onerror = () => {
                return true;
            };
        }

        let s = null
        s = document.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.innerHTML = `(function (f, o, r, c, e, _2, _4) {
                f.Force24Object = e, f[e] = f[e] || function () {
                    f[e].q = f[e].q || [], f[e].q.push(arguments)
                }, f[e].l = 1 * new Date, _2 = o.createElement(r),
                _4 = o.getElementsByTagName(r)[0], _2.async = !0, _2.src = c, _4.parentNode.insertBefore(_2, _4)
            })(window, document, "script", "https://static.websites.data-crypt.com/scripts/activity/v3/inject-v3.min.js", "f24");
        
            f24('config', 'set_tracking_id', '04efe42d-415b-4b3a-bf03-73e90823cd01');
            f24('config', 'set_client_id', '1eb6e45e-9a89-4952-8490-2db74694bbb1');
        `;
        document.head.appendChild(s);
        return () => {
            document.head.removeChild(s);
        };
    }, []);

    const addAltAttrs = () => {
        let images = document.querySelectorAll("img");
        for (let i = 0; i < images.length; i++) {
            images[i].alt = (sd && sd.img_alt) ? sd.img_alt : 'Netomnia Full Fibre Broadband';
        }
    }

    if (sd !== null && sd !== undefined) {
        return (
            <Location>
                {({ location }) => {
                    return (
                        <Helmet>
                            <title>{sd && sd.meta_title}</title>
                            <meta name="title" content={sd && sd.meta_title} />
                            <meta name="description" content={sd && sd.meta_description} />
                            <meta property="og:title" content={sd && sd.meta_title} />
                            <meta property="og:description" content={sd && sd.meta_description} />
                            <meta property="og:image:url" content={
                                (featuredImageUrl !== undefined && featuredImageUrl !== null) ?
                                    featuredImageUrl : (
                                        (sd.featured_image !== null && sd.featured_image !== undefined) ?
                                            sd.featured_image?.childImageSharp.fluid.src : FeaturedImage
                                    )
                            } />
                            <meta property="og:image" itemprop="image" content={
                                (featuredImageUrl !== undefined && featuredImageUrl !== null) ?
                                    featuredImageUrl : (
                                        (sd.featured_image !== null && sd.featured_image !== undefined) ?
                                            sd.featured_image?.childImageSharp.fluid.src : FeaturedImage
                                    )
                            } />
                            <meta property="og:url" content={location.href} />
                            <meta name="twitter:title" content={sd && sd.meta_title} />
                            <meta name="twitter:description" content={sd && sd.meta_description} />
                            <meta name="twitter:image" content={
                                (featuredImageUrl !== undefined && featuredImageUrl !== null) ?
                                    featuredImageUrl : (
                                        (sd.featured_image !== null && sd.featured_image !== undefined) ?
                                            sd.featured_image.childImageSharp.fluid.src : FeaturedImage
                                    )
                            } />
                            <meta name="twitter:card" content="summary_large_image" />
                        </Helmet>
                    );
                }}

            </Location>

        );
    } else {
        // In this case SEO info for this page is not defined in SEO component
        return (
            <Location>
                {({ location }) => {
                    return (
                        <Helmet>
                            <title>Netomnia</title>
                            <meta name="title" content="Netomnia" />
                            <meta name="description" content="Netomnia" />
                            <meta property="og:title" content="Netomnia" />
                            <meta property="og:description" content="Netomnia" />
                            <meta property="og:image:url" content={(featuredImageUrl !== undefined && featuredImageUrl !== null) ? featuredImageUrl : FeaturedImage} />
                            <meta property="og:image" itemprop="image" content={(featuredImageUrl !== undefined && featuredImageUrl !== null) ? featuredImageUrl : FeaturedImage} />
                            <meta property="og:url" content={location.href} />
                            <meta name="twitter:title" content="Netomnia" />
                            <meta name="twitter:description" content="Netomnia" />
                            <meta name="twitter:image" content={(featuredImageUrl !== undefined && featuredImageUrl !== null) ? featuredImageUrl : FeaturedImage} />
                            <meta name="twitter:card" content="summary_large_image" />
                        </Helmet>
                    );
                }}
            </Location>
        );
    }
};

export default Seo;
